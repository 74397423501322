import React, { createContext, useState, useContext } from 'react'

export const RequestContext = createContext({})

export const RequestProvider = (props) => {
  // const [pedido, setPedido] = useState({
  //   name: '',
  //   code: '',
  //   created_at: '',
  //   title: '',
  //   status: '',
  //   type: ''
  // })


  return (
    <RequestContext.Provider value={{
      // pedido, setPedido
      }}>
      {props.children}
    </RequestContext.Provider>
  )
}

export const useRequestContext = () => useContext(RequestContext)
    
/*
  {
    "situation" : "ativo",
    "name"      : "Gabriel Macao 33",
    "email"     : "maii@gabriel.com",
    "phone"     : "15997627342",
    "birthday"  : "1993-12-01",
    "whatsapp"  : "15997627342",
    "cpf"       : "48512584882",
    "rg"        : "2222222222",
    "how_meet"  : "indicacao",    
    "company_id": "",
    "id_dep"    : "",
    "genre"     : "F",
    "schooling" : "superior",
    "holder_income" : 1500,
    "working_department" : "programador",
    "marital_status" : "casado",
    "spouse_name" : "lorem ipsum",
    "spouse_birthday" : "1992-09-25",
    "father_name" : "pedro",
    "mother_name" : "angela",
    "card_type"   : "pre",
    "limit"     :  null,
    "invoice_date" : "25",
    "payment_type" : "boleto",
    "block" : "sem_bloqueio",
    "comments" : "criado pelo adm dr saude",
    "password"  : "1234"
}
*/