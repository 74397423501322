import React, { createContext, useState, useContext } from 'react'

export const RegisterContext = createContext({})

export const RegisterProvider = (props) => {
  const [isRegister, setIsRegister] = useState(false)
  const [value_installments, setValue_installments] = useState(1)
  const [contact, setContact] = useState(null)

  const [formModal, setFormModal] = useState(false)
  const [register, setRegister] = useState({
    id_client: '',
    situation: '',
    name: '',
    email: '',
    phone: '',
    birthday: '',
    whatsapp: '',
    cpf: '',
    rg: '',
    how_meet: '',
    company_id: '',
    id_dep: '',
    genre: '',
    schooling: '',
    holder_income: '',
    working_department: '',
    marital_status: '',
    spouse_name: '',
    spouse_birthday: '',
    father_name: '',
    mother_name: '',
    card_type: '',
    limit: '',
    invoice_date: '',
    payment_type: '',
    block: '',
    comments: '',
    password: ''
  })

  const [scheduleRegister, setScheduleRegister] = useState({
    block_status: '',
    block_description: '',
    request_form: '',
    status: '',
    data_atendimento: '',
    appointment_type: '',
    user_id: null,
    partner_id: null,
    partner_address_id: null,
    service_id: null,
    valor: null,
    comments: ''
  })
  const [infoSchedule, setInfoSchedule] = useState('')
  const [modo, setModo] = useState('Consulta')
  // Exibição  e  Edição  e  Consulta
  const [valueData, setValueData] = useState('')
  const [time, setTime] = useState('')
  const [date, setDate] = useState('')
  const [dashboard, setDashboard] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  return (
    <RegisterContext.Provider
      value={{
        register,
        setRegister,
        isRegister,
        setIsRegister,
        scheduleRegister,
        setScheduleRegister,
        modo,
        setModo,
        valueData,
        setValueData,
        time,
        setTime,
        date,
        setDate,
        infoSchedule,
        setInfoSchedule,
        dashboard,
        setDashboard,
        isLoading,
        setIsLoading,
        value_installments,
        setValue_installments,
        contact,
        setContact,
        formModal,
        setFormModal
      }}
    >
      {props.children}
    </RegisterContext.Provider>
  )
}

export const useRegisterContext = () => useContext(RegisterContext)
